exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-de-nous-js": () => import("./../../../src/pages/à-propos-de-nous.js" /* webpackChunkName: "component---src-pages-a-propos-de-nous-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-ar-js": () => import("./../../../src/pages/ar.js" /* webpackChunkName: "component---src-pages-ar-js" */),
  "component---src-pages-confidentialite-js": () => import("./../../../src/pages/confidentialité.js" /* webpackChunkName: "component---src-pages-confidentialite-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-contactanos-js": () => import("./../../../src/pages/contáctanos.js" /* webpackChunkName: "component---src-pages-contactanos-js" */),
  "component---src-pages-contactez-nous-js": () => import("./../../../src/pages/contactez-nous.js" /* webpackChunkName: "component---src-pages-contactez-nous-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-de-js": () => import("./../../../src/pages/de.js" /* webpackChunkName: "component---src-pages-de-js" */),
  "component---src-pages-entre-em-contato-js": () => import("./../../../src/pages/entre-em-contato.js" /* webpackChunkName: "component---src-pages-entre-em-contato-js" */),
  "component---src-pages-es-js": () => import("./../../../src/pages/es.js" /* webpackChunkName: "component---src-pages-es-js" */),
  "component---src-pages-fr-js": () => import("./../../../src/pages/fr.js" /* webpackChunkName: "component---src-pages-fr-js" */),
  "component---src-pages-geschaftsbedingungen-js": () => import("./../../../src/pages/geschäftsbedingungen.js" /* webpackChunkName: "component---src-pages-geschaftsbedingungen-js" */),
  "component---src-pages-hi-js": () => import("./../../../src/pages/hi.js" /* webpackChunkName: "component---src-pages-hi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ja-js": () => import("./../../../src/pages/ja.js" /* webpackChunkName: "component---src-pages-ja-js" */),
  "component---src-pages-kontaktiere-uns-js": () => import("./../../../src/pages/kontaktiere-uns.js" /* webpackChunkName: "component---src-pages-kontaktiere-uns-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-pages-privacidade-js": () => import("./../../../src/pages/privacidade.js" /* webpackChunkName: "component---src-pages-privacidade-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-pt-js": () => import("./../../../src/pages/pt.js" /* webpackChunkName: "component---src-pages-pt-js" */),
  "component---src-pages-ru-js": () => import("./../../../src/pages/ru.js" /* webpackChunkName: "component---src-pages-ru-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */),
  "component---src-pages-sobre-nosotros-js": () => import("./../../../src/pages/sobre-nosotros.js" /* webpackChunkName: "component---src-pages-sobre-nosotros-js" */),
  "component---src-pages-termes-et-conditions-js": () => import("./../../../src/pages/termes-et-conditions.js" /* webpackChunkName: "component---src-pages-termes-et-conditions-js" */),
  "component---src-pages-terminos-y-condiciones-js": () => import("./../../../src/pages/términos-y-condiciones.js" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-js" */),
  "component---src-pages-termos-e-condicoes-js": () => import("./../../../src/pages/termos-e-condições.js" /* webpackChunkName: "component---src-pages-termos-e-condicoes-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-uber-uns-js": () => import("./../../../src/pages/über-uns.js" /* webpackChunkName: "component---src-pages-uber-uns-js" */),
  "component---src-pages-zh-js": () => import("./../../../src/pages/zh.js" /* webpackChunkName: "component---src-pages-zh-js" */),
  "component---src-pages-конфиденциальность-js": () => import("./../../../src/pages/конфиденциальность.js" /* webpackChunkName: "component---src-pages-конфиденциальность-js" */),
  "component---src-pages-о-нас-js": () => import("./../../../src/pages/о-нас.js" /* webpackChunkName: "component---src-pages-о-нас-js" */),
  "component---src-pages-свяжитесь-с-нами-js": () => import("./../../../src/pages/свяжитесь-с-нами.js" /* webpackChunkName: "component---src-pages-свяжитесь-с-нами-js" */),
  "component---src-pages-условия-и-положения-js": () => import("./../../../src/pages/условия-и-положения.js" /* webpackChunkName: "component---src-pages-условия-и-положения-js" */),
  "component---src-pages-اتصل-بنا-js": () => import("./../../../src/pages/اتصل-بنا.js" /* webpackChunkName: "component---src-pages-اتصل-بنا-js" */),
  "component---src-pages-الخصوصية-js": () => import("./../../../src/pages/الخصوصية.js" /* webpackChunkName: "component---src-pages-الخصوصية-js" */),
  "component---src-pages-الشروط-والأحكام-js": () => import("./../../../src/pages/الشروط-والأحكام.js" /* webpackChunkName: "component---src-pages-الشروط-والأحكام-js" */),
  "component---src-pages-من-نحن-js": () => import("./../../../src/pages/من-نحن.js" /* webpackChunkName: "component---src-pages-من-نحن-js" */),
  "component---src-pages-गोपनीयता-js": () => import("./../../../src/pages/गोपनीयता.js" /* webpackChunkName: "component---src-pages-गोपनीयता-js" */),
  "component---src-pages-शर्तें-और-नियम-js": () => import("./../../../src/pages/शर्तें-और-नियम.js" /* webpackChunkName: "component---src-pages-शर्तें-और-नियम-js" */),
  "component---src-pages-संपर्क-करें-js": () => import("./../../../src/pages/संपर्क- करें.js" /* webpackChunkName: "component---src-pages-संपर्क-करें-js" */),
  "component---src-pages-हमारे-बारे-में-js": () => import("./../../../src/pages/हमारे- बारे- में.js" /* webpackChunkName: "component---src-pages-हमारे-बारे-में-js" */),
  "component---src-pages-お問い合わせ-js": () => import("./../../../src/pages/お問い合わせ.js" /* webpackChunkName: "component---src-pages-お問い合わせ-js" */),
  "component---src-pages-プライバシー-js": () => import("./../../../src/pages/プライバシー.js" /* webpackChunkName: "component---src-pages-プライバシー-js" */),
  "component---src-pages-关于我们-js": () => import("./../../../src/pages/关于我们.js" /* webpackChunkName: "component---src-pages-关于我们-js" */),
  "component---src-pages-利用規約-js": () => import("./../../../src/pages/利用規約.js" /* webpackChunkName: "component---src-pages-利用規約-js" */),
  "component---src-pages-条款和条件-js": () => import("./../../../src/pages/条款和条件.js" /* webpackChunkName: "component---src-pages-条款和条件-js" */),
  "component---src-pages-私たちに-ついて-js": () => import("./../../../src/pages/私たちに-ついて.js" /* webpackChunkName: "component---src-pages-私たちに-ついて-js" */),
  "component---src-pages-联系我们-js": () => import("./../../../src/pages/联系我们.js" /* webpackChunkName: "component---src-pages-联系我们-js" */),
  "component---src-pages-隐私政策-js": () => import("./../../../src/pages/隐私政策.js" /* webpackChunkName: "component---src-pages-隐私政策-js" */)
}

